import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import ScrollToTop from "@components/shared/scrollToTop";

export default function WhyUs(): ReactElement {
    return (
        <section className={` relative ${styles.description}`}>
            <div className="py-10 md:p-16 flex justify-center items-center flex-col">
                <h2 className="text-center font-semibold text-xl md:text-2xl md:w-3/4">
                    Get the Support You Need
                </h2>
                <p className="mt-6 text-sm md:text-base w-7/8 text-center">
                    The water contamination at Camp Lejeune adversely affected
                    the health of over a million Marines, Sailors, civilian
                    workers, and their families. If you have been affected, you
                    can receive fair compensation. Contact us today to schedule
                    a free legal consultation.
                </p>
                <ScrollToTop
                    colors={{ bg: "#e01b00", text: "white" }}
                    className="mt-10 rounded-lg"
                >
                    Get My Free Legal Case Review
                </ScrollToTop>
            </div>
        </section>
    );
}
